import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
import customParseFormat from "dayjs/plugin/customParseFormat";
require("dayjs/locale/pt");

dayjs.extend(customParseFormat);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt");

export function formatDate(
  value,
  formatString = "DD/MM/YYYY HH:mm:ss",
  originFormat = "YYYY-MM-DD HH:mm:ss"
) {
  if (!value) return "";

  const date = dayjs(value, originFormat); // Especifica o formato da data de entrada
  if (!date.isValid()) {
    return "Invalid Date";
  }

  return date.format(formatString);
}

export function currentDate(formatString = "DD/MM/YYYY HH:mm:ss") {
  const date = new Date();
  return dayjs(date).format(formatString);
}

export function formatTimezoneDate(
  value,
  formatString = "DD/MM/YYYY HH:mm:ss",
  timezone = "America/Sao_Paulo"
) {
  if (!value) return "";

  const date = new Date(value.toString());
  return dayjs(date).tz(timezone).format(formatString);
}

export function isValidDate(dateStr, formatString = "DD/MM/YYYY") {
  const date = dayjs(dateStr, formatString, true);

  if (!date.isValid()) return false;

  const currentYear = dayjs().year();
  const dateYear = date.year();

  if (dateYear > currentYear) return false;

  return true;
}
