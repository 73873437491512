import api from "./index";

export async function signIn(body) {
  return api.post("/auth/sign-in", body);
}

export async function signUp(body) {
  return api.post("/auth/sign-up", body);
}

export async function signUpDocuments(id, body) {
  return api.put(`/auth/sign-up/${id}/documents`, body);
}

export async function forgotPassword(body) {
  return api.post("/auth/forgot-password", body);
}

export async function confirmAccount(body) {
  return api.put(`/auth/confirm-account`, body);
}

export async function resendConfirmAccount(body) {
  return api.post(`/auth/resend-confirm-email`, body);
}

export async function loadSession() {
  return api.get("/auth");
}

forgotPassword;
