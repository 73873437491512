import { isValidDate } from "./dates";

export default {
  required: (v) => !!v || "Campo Obrigatório",
  email: (v) => /.+@.+\..+/.test(v) || "Informe um e-mail válido",
  phone: (v) => (!!v && v.length === 15) || "Informe um número válido",
  password: (v) =>
    (!!v && v.length >= 8) || "Use 8 caracteres ou mais para sua senha",
  document: (v) =>
    v.length === 14 || v.length === 18 || "Informe uma numeração válida",
  cpf: (v) => v.length === 14 || "Informe um CPF válido",
  cnpj: (v) => v.length === 18 || "Informe um CNPJ válido",
  zipCode: (v) => v.length === 9 || "Informe um CEP válido",
  birthDate: (v) => isValidDate(v) || "Informe uma data válida",
};
