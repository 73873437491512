import "dayjs/locale/pt-br";

export function formatAmount(value, decimal = 2) {
  if (!value) value = 0;

  const og = Math.pow(10, decimal);
  value = Math.floor(value * og) / og;

  return Number(value.toFixed(decimal));
}

export function currencyMask(value, min = 2, max = 2) {
  value = formatAmount(value, min);

  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
}

export function parseNumber(value) {
  return Number(value.replace(/\./g, "").replace(/,/g, "."));
}

export function capitalizeSentence(value) {
  if (!value) return "";

  const values = value.split(" ");

  return values
    .map((e) => capitalizeWord(e))
    .toString()
    .replaceAll(",", " ");
}

export function capitalizeWord(value) {
  if (!value) return "";

  return (
    value.toString().charAt(0).toUpperCase() +
    value.toString().substring(1).toLowerCase()
  );
}

export function formatDocument(value) {
  value = String(value);

  if (value.length === 11)
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

  if (value.length === 14)
    return value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    );

  return value;
}
