import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../modules/main/Main.vue";
import Auth from "../modules/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Home" },
  },
  {
    path: "/",
    name: "Main",
    redirect: { name: "Home" },
    component: Main,
    children: [
      {
        path: "home",
        name: "Home",
        meta: { title: "Início", requiresAuth: true },
        component: () => import("@/modules/main/views/home/Home.vue"),
      },
      {
        path: "profile",
        name: "Profile",
        redirect: { name: "Informations" },
        meta: { title: "Perfil", requiresAuth: true },
        component: () => import("@/modules/main/views/profile/Profile.vue"),
        children: [
          {
            path: "informations",
            name: "Informations",
            meta: { title: "Informações pessoais", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/profile/children/informations/Informations.vue"
              ),
          },
          {
            path: "transactions",
            name: "Transactions",
            meta: { title: "Informações", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/profile/children/transactions/Transactions.vue"
              ),
          },
          {
            path: "change-password",
            name: "ChangePassword",
            meta: { title: "Alterar senha", requiresAuth: true },
            component: () =>
              import(
                "@/modules/main/views/profile/children/changePassword/ChangePassword.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "SignIn" },
    component: Auth,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        meta: { title: "Entrar", preventAuth: true },
        component: () => import("@/modules/auth/views/signIn/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "SignUp",
        meta: { title: "Criar Conta", preventAuth: true },
        component: () => import("@/modules/auth/views/signUp/SignUp.vue"),
      },
      {
        path: "forgot-password",
        name: "ForgotPassword",
        meta: { title: "Esqueci minha senha", preventAuth: true },
        component: () =>
          import("@/modules/auth/views/forgotPassword/ForgotPassword.vue"),
      },
      {
        path: "confirm-account",
        name: "ConfirmAccount",
        meta: { title: "Confirmar Conta" },
        component: () =>
          import("@/modules/auth/views/confirmAccount/ConfirmAccount.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
