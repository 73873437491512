<template>
  <v-layout class="main-wrapper" column>
    <v-app-bar class="header-bar" absolute top>
      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        class="btn-icon ml-2"
        icon
        @click="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <div v-else class="d-flex align-center">
        <v-img
          :src="require('@/assets/jp2pay/logomark.png')"
          class="ml-16 mr-12"
          max-width="2rem"
          contain
        />

        <a
          href="https://jp2pay.com"
          class="text-decoration-none font-weight-bold white--text mr-6"
        >
          Início
        </a>

        <router-link
          v-for="(item, i) in _routes"
          v-slot="{ href, navigate, isActive }"
          class="text-decoration-none font-weight-regular pointer mr-6"
          :to="item.path"
          :key="i"
        >
          <span
            :class="{ 'font-weight-bold': isActive }"
            :href="href"
            @click="navigate"
          >
            {{ item.label }}
          </span>
        </router-link>
      </div>

      <v-spacer />

      <router-link
        class="d-flex align-center text-decoration-none font-weight-bold white--text mr-2 mr-md-16"
        :to="{ name: 'Profile' }"
      >
        <div class="mr-4">
          {{ _user.name }}
        </div>

        <v-avatar size="2.5rem" color="neutral6">
          <v-img v-if="_userImage" :src="_userImage" />

          <v-icon v-else small>far fa-user</v-icon>
        </v-avatar>
      </router-link>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="drawer"
      class="side-drawer rounded-r-xl"
      color="neutral3"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <div class="d-flex align-center pa-4">
          <v-img
            :src="require('@/assets/jp2pay/logomark.png')"
            class="mr-2"
            max-width="1.75rem"
            height="1.75rem"
            contain
          />

          <span class="text-h6 font-weight-bold"> JP2PAY </span>
        </div>

        <v-list class="py-0" color="transparent" nav>
          <v-list-item class="router-item" href="https://jp2pay.com">
            <v-list-item-title> Início </v-list-item-title>
          </v-list-item>

          <router-link
            v-for="(item, i) in _routes"
            v-slot="{ href, navigate, isActive }"
            class="mb-1"
            :to="item.path"
            :key="i"
            custom
          >
            <v-list-item class="router-item" :href="href" @click="navigate">
              <v-list-item-title :class="{ 'primary--text': isActive }">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </div>
    </v-navigation-drawer>

    <v-banner
      v-if="!_user.email_verified && showBanner"
      class="mx-4 mt-16 rounded-lg"
      outlined
    >
      <v-avatar
        v-if="$vuetify.breakpoint.mdAndUp"
        slot="icon"
        color="error"
        size="2rem"
      >
        <v-icon large> mdi-information-symbol </v-icon>
      </v-avatar>

      <div class="text-subtitle-1">
        💡 Confirme seu endereço de E-mail! Verifique sua caixa de entrada e
        clique no link de confirmação que enviamos para completar o registro.
      </div>

      <template v-slot:actions>
        <div class="ma-4">
          <v-btn
            class="text-none rounded-lg"
            color="primary"
            :loading="loadingResend"
            :disabled="loadingResend"
            depressed
            @click="resendConfirmAccount()"
          >
            Reenviar e-mail
          </v-btn>
        </div>
      </template>
    </v-banner>

    <router-view class="py-16" />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { resendConfirmAccount } from "@/services/auth";
import { handleAlert } from "@/helpers";

export default {
  name: "Main",

  data() {
    return {
      drawer: true,
      loadingResend: false,
      showBanner: true,
    };
  },

  computed: {
    ...mapGetters({
      _user: "user",
    }),

    _routes() {
      return [{ label: "Negociar", path: "/home" }];
    },

    _userImage() {
      if (!this._user.image) return;

      return process.env.VUE_APP_STORAGE_URL + "/" + this._user.image;
    },
  },

  methods: {
    async resendConfirmAccount() {
      try {
        this.loadingResend = true;
        await resendConfirmAccount();

        this.handleAlert(
          "Solicitação enviada com sucesso, confira sua caixa de entrada",
          "success"
        );

        this.showBanner = false;
      } catch (error) {
        this.handleAlert(error?.data?.message, "error");
      } finally {
        this.loadingResend = false;
      }
    },

    handleAlert,
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
