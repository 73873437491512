export * from "./alerts";
export * from "./inputs";
export * from "./strings";
export * from "./dates";
export { default as validations } from "./validations";

export function fetchTotalPages(total, limit = 15) {
  let value = total / limit;
  if (value % 1 !== 0) value = value + 1;

  return Math.trunc(value);
}
