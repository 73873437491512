<template>
  <v-layout class="auth-wrapper" fill-height>
    <router-view />
  </v-layout>
</template>

<script>
export default {
  name: "Auth",
};
</script>

<style lang="scss" src="./style.scss" scoped />
